@charset "UTF-8";
/* text-ellipsis (말줄임..)  */
/* @param : $l ( 기본값 1 줄 ) */
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.displayNone {
  display: none;
}

button, input, .cursorPointer {
  cursor: pointer;
}

.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 20px;
  align-items: center;
}
.popupWrapper img {
  max-width: 90vw;
  width: 100%;
  max-height: 90vh;
  height: auto;
}
.popupWrapper .popupBtnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.popupWrapper .popupBtnWrapper .popupBtn {
  z-index: 100;
  max-height: 48px;
  font-size: 2vw;
  color: transparent;
  font-family: "NotoSansKR";
}
.popupWrapper .popupBtnWrapper .popupBtn:first-child {
  width: 33%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.popupWrapper .popupBtnWrapper .popupBtn:nth-child(2) {
  width: 6%;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (max-width: 800px) {
  .popupWrapper .popupBtnWrapper .popupBtn {
    margin-right: 0;
    font-size: 3vw;
    color: black;
    font-family: "NotoSansKR";
  }
}

.projectWrapper {
  height: 100%;
}

.projectIntro {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.0833333333vw;
  color: white;
  font-weight: 700;
  font-family: "NotoSansKR";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: transparent;
  text-align: center;
  word-break: word-break;
}
@media (max-width: 800px) {
  .projectIntro {
    height: 66.666%;
  }
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  .projectIntro {
    height: 100%;
  }
}
@media (min-width: 1920px) {
  .projectIntro {
    font-size: 2.5rem;
  }
}
@media (max-width: 800px) {
  .projectIntro {
    align-items: flex-start;
    font-size: 5vw;
    padding-left: 4.1666666667vw;
    padding-right: 4.1666666667vw;
    text-align: left;
    background-position: 12% 0%;
  }
  .projectIntro .intro1 {
    display: none;
  }
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  .projectIntro {
    font-size: 2.8125vw !important;
  }
}
@media (min-width: 801px) {
  .projectIntro .intro2 {
    display: none;
  }
}

.otherPageWrapper {
  display: grid;
  width: 100%;
  height: 100%;
}
@media (min-width: 801px) {
  .otherPageWrapper {
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
  }
}
.projectVideoWrapper .videoWrapper {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: multiply;
  position: relative;
}
@media (max-width: 800px) {
  .projectVideoWrapper .videoWrapper {
    width: 100vw;
  }
}
.projectVideoWrapper .videoWrapper .playIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.875rem;
  height: auto;
  margin: 0.78125rem;
}
@media (max-width: 800px) {
  .projectVideoWrapper .videoWrapper .playIcon {
    width: 7.4166666667vw;
    height: auto;
    margin: 0.66875rem 1.41875rem;
  }
}
.projectVideoWrapper .videoWrapper .videoTitle {
  font-size: 2.0833333333vw;
  color: white;
  font-weight: 700;
  font-family: "NotoSansKR";
  margin-left: 4rem;
}
@media (min-width: 1920px) {
  .projectVideoWrapper .videoWrapper .videoTitle {
    font-size: 2.5rem;
  }
}
@media (max-width: 800px) {
  .projectVideoWrapper .videoWrapper .videoTitle {
    font-size: 1.125rem;
    margin-left: 1.1875rem;
  }
}
.projectVideoWrapper .videoWrapper:hover {
  background-blend-mode: normal;
}
.projectVideoWrapper .videoWrapper:not(:hover) {
  background-blend-mode: multiply;
}

.firstPageWrapper {
  display: grid;
  grid-template-columns: 50% 50%;
}
@media (min-width: 801px) {
  .firstPageWrapper {
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 800px) {
  .firstPageWrapper {
    width: 100%;
    height: 33.333% !important;
  }
  .firstPageWrapper .videoWrapper:nth-child(2) {
    display: none;
  }
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  .firstPageWrapper .videoWrapper:nth-child(1) {
    display: none;
  }
}

.carouselWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 90%;
}
.carouselWrapper .shrinkBlank {
  flex-shrink: 3;
}
.carouselWrapper .clientHeaderWrppaer {
  width: 100%;
}
.carouselWrapper .clientHeaderWrppaer .clientViewContainer {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 76%;
  height: 70vh;
}
@media (max-width: 800px) {
  .carouselWrapper .clientHeaderWrppaer .clientViewContainer {
    height: 65vh;
    width: 100%;
  }
}
.carouselWrapper .clientHeaderWrppaer .clientViewContainer .clientListContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 3400px; /* 보여야 하는 이미지 + 클론이미지 전체 합 */
  height: 90%;
  flex-wrap: nowrap;
  animation: bannermove 12s linear infinite;
}
@media (max-width: 800px) {
  .carouselWrapper .clientHeaderWrppaer .clientViewContainer .clientListContainer {
    height: 100%;
  }
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  .carouselWrapper .clientHeaderWrppaer .clientViewContainer .clientListContainer {
    width: 27000px;
    animation: bannermove 60s linear infinite;
    height: 54%;
  }
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  .carouselWrapper .clientHeaderWrppaer .clientViewContainer .clientList {
    display: flex;
  }
}
.carouselWrapper .clientHeaderWrppaer .clientViewContainer .clientRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.carouselWrapper .clientHeaderWrppaer .clientViewContainer .clientRow > div {
  margin: 40px 70px;
}
@media (max-width: 800px) {
  .carouselWrapper .clientHeaderWrppaer .clientViewContainer .clientRow > div {
    margin: 16px 70px;
  }
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  .carouselWrapper .clientHeaderWrppaer .clientViewContainer .clientRow > div {
    margin: 16px 50px;
  }
}
@media (min-width: 801px) and (max-height: 960px) {
  .carouselWrapper .clientHeaderWrppaer .clientViewContainer .clientRow > div {
    margin: 30px 100px;
  }
}
.carouselWrapper .clientHeaderWrppaer .clientViewContainer .clientRow img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  .carouselWrapper .clientHeaderWrppaer .clientViewContainer .clientRow img {
    width: 84%;
  }
}

@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
.clientHeader {
  font-size: 3.25rem;
  color: #1E1E1E;
  font-weight: 900;
  font-family: "NotoSansKR";
  text-align: center;
  padding: 100px 0 30px;
}
@media (max-width: 800px) {
  .clientHeader {
    font-size: 1.625rem;
    color: #1E1E1E;
    font-weight: 700;
    font-family: "NotoSansKR";
    margin: 84px 0 44px;
    padding: 0;
  }
}
@media (min-width: 801px) and (max-height: 960px) {
  .clientHeader {
    font-size: 2.7083333333vw;
    color: #1E1E1E;
    font-weight: 900;
    font-family: "NotoSansKR";
    padding: 30px 0;
  }
}/*# sourceMappingURL=Project.css.map */