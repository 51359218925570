@import '../../../common/Common';
.projectWrapper{
    height: 100%;
}
.projectIntro{
    height: 50%;
    @include mobile{
        height: 66.666%;
        @media screen and (orientation:landscape){
            height: 100%;
        }
    }
    @include flex($d:column ,$jc:center, $ai:center);
    @include font($s:pxToVwPC(40), $w:700, $c:white);
    @include backgroundImage($bs:cover);
    @include WideDesktop(){
        font-size: pxToRem(40);
    }
    @include mobile(){
        align-items: flex-start;
        font-size: pxToVwMobile(18);
        padding-left: pxToVwMobile(15);
        padding-right: pxToVwMobile(15);
        text-align: left;
        background-position: 12% 0%;
        .intro1{
            display: none
        }
        @media screen and (orientation:landscape){
            font-size: pxToVhMobile(18) !important;
        }
    }
    @include desktop(){
        .intro2{
            display: none
        }
    }
    @include textCenterWordBreak();
    
}

.otherPageWrapper{
    @include display-grid;
    
    width: 100%;
    height: 100%;
    @include desktop(){
        @include grid-template-columns(50% 50%);
        @include grid-template-rows(50% 50%);
    }
    @include mobile(){
    }
    
}
.projectVideoWrapper{
    .videoWrapper{
        cursor: pointer;
        width: 100%;
        @include mobile{
            width: 100vw;
        }
        height: 100%;
        @include flex($d:column, $jc:center, $ai:flex-start);
        @include backgroundImage($bs:cover, $bc:rgba(0, 0, 0, 0.3)){
            background-blend-mode: multiply;
        };
        position: relative;
        .playIcon{
            @include position($t:0, $l:0){
                width: pxToRem(46);
                height: auto;
                margin: pxToRem(12.5);
                @include mobile(){
                    width: pxToVwMobile(26.7);
                    height: auto;
                    margin: pxToRem(10.7) pxToRem(22.7);
                }
            }
            
        }
        .videoTitle{
            @include font($c:white, $s:pxToVwPC(40), $w:700);
            @include WideDesktop(){
                font-size: pxToRem(40);
            }
            margin-left: pxToRem(64);
            @include mobile(){
                font-size: pxToRem(18);
                margin-left: pxToRem(19);
            }
        }
        &:hover{
            background-blend-mode: normal;
            // &>.videoTitle{
            //     @include animation($a:fadeout,$s:0.3s);
            // }
        }
        &:not(:hover){
            background-blend-mode: multiply;
            // &>.videoTitle{
            //     @include animation($a:fadein,$s:0.3s);
            // }
        }
    }
//     cursor: pointer;
//     @include desktop(){
//         height: 50%;
//         @include flex($jc:center);
//         .videoWrapperEmpty{
//             height: 100%;
//             flex-shrink: 6;
//             flex-basis: 100px;
//             flex-grow: 1;
//         }
//         &.emptyWrapper{
//             display: none;
//         }
//     }
//     @include mobile(){
//         // height: 66.666%;
//         @include flex();
//         &.nullItemWrapper{
//             height: 33.333%;
//         }
//         &.emptyWrapper{
//             @include backgroundImage($bs:cover){
//                 background-blend-mode: multiply;
//             };
//             background-image: url('./images/ui_bg_non@3x.png');
//         }
    }

.firstPageWrapper{
    @include display-grid;
    @include grid-template-columns(50% 50%);
    @include desktop(){
        height: 50%;
        @include flex($jc:center);
    }
    @include mobile{
        width: 100%;
        height: 33.333% !important;
        .videoWrapper:nth-child(2){
            display: none;
        }
        @media screen and (orientation:landscape){
            .videoWrapper:nth-child(1){
                display: none;
            }
        }
    }        
}

.carouselWrapper{
    @include flex($jc:center);
    height: 90%;
    .shrinkBlank{
        flex-shrink: 3;
    }
    .clientHeaderWrppaer{
        width: 100%;
        .clientViewContainer{
            margin: 0 auto;
            overflow:hidden;
            position:relative;
            width:76%;
            height:70vh;
            @include mobile{
                height: 65vh;
                width: 100%
            }
            .clientListContainer{
                @include flex();
                width:3400px; /* 보여야 하는 이미지 + 클론이미지 전체 합 */
                height:90%;
                flex-wrap:nowrap;
                animation: bannermove 12s linear infinite;
                @include mobile{
                    height: 100%;
                    @media screen and (orientation:landscape){
                        width:27000px;
                        animation: bannermove 60s linear infinite;
                        height: 54%;
                    }
                } 
            }
            .clientList{
                @include mobile{
                    @media screen and (orientation:landscape){
                        display: flex;
                    }
                }
            }
                .clientRow{
                    @include flex();
                }
                    .clientRow > div{
                        margin: 40px 70px;
                        @include mobile{
                            margin:16px 70px;
                            @media screen and (orientation:landscape){
                                margin:16px 50px;
                            }
                        } 
                        @include desktop{
                            @media(max-height:960px){
                                margin: 30px 100px;
                            }
                        }
                    }
                        .clientRow img{
                            width:100%;
                            height:100%;
                            @include mobile{
                                @media screen and (orientation:landscape){
                                    width:84%;
                                }
                            } 
                        }
        }
    }
}

@keyframes bannermove {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-50%, 0);
    }
}

.clientHeader{
    @include font($s:pxToRem($s:52), $c:#1E1E1E, $w:900);
    text-align: center;
    padding: 100px 0 30px;
    @include mobile{
        @include font($s:pxToRem($s:26), $c:#1E1E1E, $w:700);
        margin: 84px 0 44px;
        padding: 0;

    }
    @include desktop{
        @media (max-height:960px){
            @include font($s:pxToVwPC($s:52), $c:#1E1E1E, $w:900);
            padding: 30px 0;
        }
    }
}

