@charset "UTF-8";
/* text-ellipsis (말줄임..)  */
/* @param : $l ( 기본값 1 줄 ) */
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.displayNone {
  display: none;
}

button, input, .cursorPointer {
  cursor: pointer;
}

.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 20px;
  align-items: center;
}
.popupWrapper img {
  max-width: 90vw;
  width: 100%;
  max-height: 90vh;
  height: auto;
}
.popupWrapper .popupBtnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.popupWrapper .popupBtnWrapper .popupBtn {
  z-index: 100;
  max-height: 48px;
  font-size: 2vw;
  color: transparent;
  font-family: "NotoSansKR";
}
.popupWrapper .popupBtnWrapper .popupBtn:first-child {
  width: 33%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.popupWrapper .popupBtnWrapper .popupBtn:nth-child(2) {
  width: 6%;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (max-width: 800px) {
  .popupWrapper .popupBtnWrapper .popupBtn {
    margin-right: 0;
    font-size: 3vw;
    color: black;
    font-family: "NotoSansKR";
  }
}

.innerM {
  height: 100%;
  display: none;
}
@media (max-width: 950px) {
  .innerM {
    display: block;
  }
}

.pcHiddenMHidden {
  visibility: hidden;
}

.pcHiddenMNone {
  visibility: hidden;
}
@media (max-width: 950px) {
  .pcHiddenMNone {
    display: none !important;
  }
}

.pcNoneMNone {
  display: none !important;
}
@media (max-width: 950px) {
  .pcNoneMNone {
    display: none !important;
  }
}

.contactOuter {
  height: 100%;
  overflow: auto;
}

.ContactWholeWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 950px) {
  .ContactWholeWrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.ContactWholeWrapper .emptydiv {
  height: 100%;
  flex-shrink: 4;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .emptydiv {
    display: none;
  }
}
.ContactWholeWrapper .ContactWrapper {
  border: 1px solid transparent;
  height: 100%;
  width: 1200px;
  min-width: 300px;
  margin-right: 50px;
  margin-left: 50px;
}
.ContactWholeWrapper .ContactWrapper .ContactLine {
  height: 1px;
  width: 100%;
  background-color: black;
  color: black;
}
.ContactWholeWrapper .ContactWrapper .ContactHeader {
  margin-top: 5%;
}
.ContactWholeWrapper .ContactWrapper .ContactHeader .ContactHeaderText, .ContactWholeWrapper .ContactWrapper .ContactHeader .ContactHeaderTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.ContactWholeWrapper .ContactWrapper .ContactHeader .ContactHeaderText {
  font-size: 3.4375rem;
  color: black;
  font-weight: 900;
  font-family: "NotoSansKR";
}
.ContactWholeWrapper .ContactWrapper .ContactHeader .ContactHeaderTitle {
  font-size: 1.625rem;
  color: black;
  font-weight: 700;
  font-family: "NotoSansKR";
}
.ContactWholeWrapper .ContactWrapper .ContactHeader .ContactHeaderSubTitle {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.8125rem;
  color: red;
  font-weight: 400;
  font-family: "NotoSansKR";
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactHeader {
    margin-top: 7%;
  }
  .ContactWholeWrapper .ContactWrapper .ContactHeader .ContactHeaderText {
    font-size: 1.625rem;
    color: black;
    font-weight: 700;
    font-family: "NotoSansKR";
  }
  .ContactWholeWrapper .ContactWrapper .ContactHeader .ContactHeaderTitle {
    font-size: 0.75rem;
    color: black;
    font-weight: 400;
    font-family: "NotoSansKR";
  }
  .ContactWholeWrapper .ContactWrapper .ContactHeader .ContactHeaderSubTitle {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.8125rem;
    color: red;
    font-weight: 400;
    font-family: "NotoSansKR";
  }
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .ContactWholeWrapper .ContactWrapper > div {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}
.ContactWholeWrapper .ContactWrapper .starMark {
  color: red;
  font-family: "NotoSansKR";
}
.ContactWholeWrapper .ContactWrapper .ContactContainer {
  position: relative;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer.ContactContainerPC {
    display: none;
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 3% auto;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactInputWrapperL {
  align-items: flex-start;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactInputWrapperG {
  padding-bottom: 1%;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactInputWrapperS {
  justify-content: space-between;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactInputWrapperS {
    justify-content: space-around;
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactInputWrapperC {
  display: block;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper textarea, .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper input[type=text] {
  background-color: #F5F5F5;
  border-radius: 20px;
  padding-left: 15px;
  height: 2.875rem;
  outline: none;
  border: none;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper textarea::-moz-placeholder, .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper input[type=text]::-moz-placeholder {
  font-size: 0.8125rem;
  color: #B7B7B7;
  font-weight: 400;
  font-family: "NotoSansKR";
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper textarea::placeholder, .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper input[type=text]::placeholder {
  font-size: 0.8125rem;
  color: #B7B7B7;
  font-weight: 400;
  font-family: "NotoSansKR";
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper textarea {
  resize: none;
  overflow: hidden;
  height: calc(11.25rem - 52px);
  width: 100%;
  padding: 26px;
  border-radius: 20px;
  margin-top: 8px;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper textarea {
    padding: 16px;
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputHeader {
  width: 23%;
  font-size: 1.375rem;
  color: black;
  font-weight: 700;
  font-family: "NotoSansKR";
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputHeader {
    margin-bottom: 0.5rem;
    width: 100%;
    font-size: 1.125rem;
    color: black;
    font-weight: 700;
    font-family: "NotoSansKR";
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputHeader.ContactFinished {
  width: 100%;
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
  font-size: 1.625rem;
  color: #1E1E1E;
  font-weight: 100;
  font-family: "NotoSansKR";
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputHeader.ContactFinished {
    font-size: 1rem;
    color: #1E1E1E;
    font-weight: 100;
    font-family: "NotoSansKR";
    margin-top: 40%;
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer {
  width: 90%;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer {
    width: 100%;
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  justify-items: flex-end;
  row-gap: 5%;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList .ContactCheckboxItem {
  border-radius: 15px;
  font-size: 0.8125rem;
  color: white;
  font-weight: 700;
  font-family: "NotoSansKR";
  width: 7.5rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  margin-right: 1.5rem;
  height: 2rem;
  margin-right: 6.25rem;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList .ContactCheckboxItem.selected {
  background-color: #00A5FA;
  color: white;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList .ContactCheckboxItem.nonSelected {
  background-color: #EAEAEA;
  color: white;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList .ContactCheckboxItem:first-child {
  margin-right: 3.75rem;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactInputList {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  row-gap: 10%;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactInputList .ContactInputitems {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactInputList .ContactInputitems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactInputList .ContactInputitems div {
  width: 35%;
  word-break: keep-all;
  text-align: right;
  font-size: 0.8125rem;
  color: black;
  font-weight: 700;
  font-family: "NotoSansKR";
  position: relative;
  padding-right: 15px;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactInputList .ContactInputitems div {
    text-align: left;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactInputList .ContactInputitems div.firstItems {
    margin-top: 0 !important;
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactInputList .ContactInputitems div .starMark {
  position: absolute;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactInputList .ContactInputitems input {
  width: 65%;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactInputList {
    height: 100%;
    row-gap: 7%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactInputList .ContactInputitems {
    width: 100%;
  }
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactInputList .ContactInputitems input {
    width: 100% !important;
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList2 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  justify-items: flex-end;
  row-gap: 5%;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList2 .ContactCheckboxItem {
  border-radius: 15px;
  font-size: 0.8125rem;
  color: white;
  font-weight: 700;
  font-family: "NotoSansKR";
  width: 7.5rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  margin-right: 1.5rem;
  width: 11.25rem;
  margin-bottom: 1%;
  margin-right: 0;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList2 .ContactCheckboxItem.selected {
  background-color: #00A5FA;
  color: white;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList2 .ContactCheckboxItem.nonSelected {
  background-color: #EAEAEA;
  color: white;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList2 {
    justify-items: flex-start;
    margin-bottom: 3%;
  }
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList2 .ContactCheckboxItem {
    margin-bottom: 2%;
    border-radius: 15px;
    font-size: 0.8125rem;
    color: white;
    font-weight: 700;
    font-family: "NotoSansKR";
    width: 7.5rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    margin-right: 1.5rem;
    width: 9.375rem;
    margin-right: 2%;
  }
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList2 .ContactCheckboxItem.selected {
    background-color: #00A5FA;
    color: white;
  }
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList2 .ContactCheckboxItem.nonSelected {
    background-color: #EAEAEA;
    color: white;
  }
}
@media (max-width: 403px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper .ContactInputContainer.ContactCheckboxList2 {
    row-gap: 1%;
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactPrivacyWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.5rem;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactPrivacyWrapper {
    justify-content: flex-start;
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactPrivacyWrapper .ant-checkbox-wrapper {
  margin-right: 0.75rem;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactBtnWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.5rem;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactBtnWrapper {
    justify-content: center;
  }
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactBtnWrapper div {
  height: 52px;
  line-height: 52px;
  width: 180px;
  border: none;
  border-radius: 20px;
  background-color: #0055F0;
  color: white;
  text-align: center;
  cursor: pointer;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactBtnWrapper div:hover {
  background-color: white;
  color: #0055F0;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactBtnWrapper div:active {
  background-color: #0055F0;
  color: white;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactBtnWrapper div:hover {
  background-color: #0055F0;
  color: white;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactBtnWrapper .disabled {
  background-color: #EAEAEA;
}
.ContactWholeWrapper .ContactWrapper .ContactContainer .ContactInputWrapper.ContactBtnWrapper .disabled:hover {
  background-color: #EAEAEA;
  color: white;
}
@media (max-width: 950px) {
  .ContactWholeWrapper .ContactWrapper .ContactContainer {
    border: 1px solid transparent;
  }
}
.ContactWholeWrapper .ContactWrapper .finishedWrapper {
  border: 1px solid black;
  text-align: center;
  height: 60vh;
}/*# sourceMappingURL=Contact.css.map */