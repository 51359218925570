@import '../../../common/Common';


$partnerSectionheight : 402px;
$footerSectionheight : 275px;
$footerSectionheightMobile : 229px;
$partnerSectionheightMobile :  360px;
// calc(100% - $footerSectionheightMobile);


.footerPageWrapper{
    width: 100%;
    height: calc($partnerSectionheight + $footerSectionheight) !important;
    @include textCenterWordBreak();
    @include flex($d:column, $jc:flex-end);
    @include backgroundImage($bs:cover);
    @include mobile(){
        background-position: 10 0% 80%;
        height: calc($partnerSectionheightMobile + $footerSectionheightMobile) !important;
        @media (max-height:589px){
            height : 100vh !important;
        }
    }
    background-image: url('./images/about_bg_full.png');
    &.noPartner{
        height: $footerSectionheight !important;
        @include mobile(){
            height: $footerSectionheightMobile !important;
            @media screen and (orientation:landscape){
                display: none;
            }
        }
    }
    .partnerWrapper{
        height: $partnerSectionheight;
        @include flex($d:column, $jc : space-around);
        padding-bottom:pxToRem(30);
        @include mobile(){
            height: $partnerSectionheightMobile !important;
            justify-content: center;
            padding-bottom:0;
            @media screen and (orientation:landscape){
                justify-content: center;
                @at-root .footerPageWrapper{
                    height: calc(100vh - $mobile-navbar-height) !important;
                    justify-content: space-around;
                }
            }
            @media (max-height:589px){
                height : 50vh !important;
            }
        }
        .topText{
            @include font($s:pxToRem(52), $w:900, $c:white);
            @include mobile(){
                @include font($s:pxToRem(26), $w:700, $c:white);
            }
        }
        .middleText{        
            @include font($s:pxToRem(26), $w:700, $c:white);
            @include mobile(){
                width: 80%;
                word-break: keep-all;
                @include font($s:pxToRem(18), $w:700, $c:white);
                margin-top: pxToRem(16);
                margin-bottom: pxToRem(16);
            }
        }
        .bottomBtn{
            $buttonHeight : 64px;
            @include divBtn($b:3px solid white, $w:180px, $h:$buttonHeight, $br:60px, $c:white){
                line-height: $buttonHeight - 6px;
                &:hover{
                    color:white;
                    background-color: transparent;
                }
            };
            @include font($s:pxToRem(18), $w:700, $c:white);
            @include mobile(){
                width: 208px;
            }
        }
    }
    .footerContainer{
        width: 100%;
        height: $footerSectionheight;
        background: #1E1E1E;
        color: white;
        @include flex($jc:center, $ai:flex-end);
        @include mobile(){
            @include flex($d:column, $jc:space-around, $ai:flex-start);
            padding: 5%;
            &>div, &>img{
                margin-left: 5% !important;
                margin-right: 5% !important;
            }
            text-align: left;
            height: $footerSectionheightMobile;
            @media (max-height:589px){
                height : 45vh !important;
            }
            @media screen and (orientation:landscape){
                height: 100% !important;
            }
        }
            padding-bottom: pxToRem(50.34);
            .footerLogo{
                width: pxToRem(81);
                height: pxToRem(26);
                margin-right: pxToVwPC(21);
                margin-left: pxToVwPC(50);
            }
            .footerAddress, .footerCopy{
                font-size: pxToRem(13);
            }
            .footerAddress{
                margin-right: pxToRem(50);
                flex-shrink: 3;
                @include mobile(){
                    margin-right: 0;
                } 
            }
            .footerCopy{
                margin-right: pxToRem(70);
                flex-shrink: 3;
                @include mobile(){
                    margin-right: 0;
                }
            }
            .footerPolicy{
                // margin-right: pxToRem(50);
                font-weight: 700;
                width: pxToRem(150);
                @include flex($jc:space-between);
                a{
                    text-decoration: none;
                    color:white;
                }
                .footersocial{
                    width: pxToRem(29.04);
                    height: pxToRem(20.48);
                    margin-left: pxToVwPC(21);
                    @include backgroundImage(){
                        background-image:url('./images/ui_ic_youtube@3x.png');
                    }
                }
            }
            .footerPolicyM{
                @extend .footerPolicy;
                @include desktop(){
                    display: none;
                }
            }
        } 
}



