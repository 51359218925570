@charset "UTF-8";
/* text-ellipsis (말줄임..)  */
/* @param : $l ( 기본값 1 줄 ) */
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.displayNone {
  display: none;
}

button, input, .cursorPointer {
  cursor: pointer;
}

.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 20px;
  align-items: center;
}
.popupWrapper img {
  max-width: 90vw;
  width: 100%;
  max-height: 90vh;
  height: auto;
}
.popupWrapper .popupBtnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.popupWrapper .popupBtnWrapper .popupBtn {
  z-index: 100;
  max-height: 48px;
  font-size: 2vw;
  color: transparent;
  font-family: "NotoSansKR";
}
.popupWrapper .popupBtnWrapper .popupBtn:first-child {
  width: 33%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.popupWrapper .popupBtnWrapper .popupBtn:nth-child(2) {
  width: 6%;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (max-width: 800px) {
  .popupWrapper .popupBtnWrapper .popupBtn {
    margin-right: 0;
    font-size: 3vw;
    color: black;
    font-family: "NotoSansKR";
  }
}

.CommonFormatWrapper {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.CommonFormatWrapper .emptyDiv {
  flex-shrink: 3;
  flex-basis: 18.75vw;
}
@media (max-width: 800px) {
  .CommonFormatWrapper .emptyDiv {
    display: none;
  }
}
.CommonFormatWrapper .CommonFormatContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  flex-basis: 62.5vw;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 801px) {
  .CommonFormatWrapper .CommonFormatContainer {
    min-width: 800px;
  }
}
@media (max-width: 800px) {
  .CommonFormatWrapper .CommonFormatContainer {
    min-width: 99vw;
  }
}
.CommonFormatWrapper .CommonFormatContainer .emptyDiv {
  display: none;
}
.CommonFormatWrapper .CommonFormatContainer img {
  height: 99px;
  width: auto;
}
@media (max-width: 800px) {
  .CommonFormatWrapper .CommonFormatContainer img {
    height: 62px;
    width: auto;
  }
}
.CommonFormatWrapper .CommonFormatContainer .headerText {
  font-size: 4rem;
  color: white;
  font-weight: 900;
  font-family: "NotoSansKR";
  word-break: keep-all;
}
@media (max-width: 800px) {
  .CommonFormatWrapper .CommonFormatContainer .headerText {
    font-size: 1.625rem;
    margin-bottom: 0.5rem;
  }
}
.CommonFormatWrapper .CommonFormatContainer .bodyText {
  font-size: 1.625rem;
  color: white;
  font-weight: 400;
  font-family: "NotoSansKR";
  margin-top: 0.5rem;
}
@media (max-width: 800px) {
  .CommonFormatWrapper .CommonFormatContainer .bodyText {
    font-size: 0.75rem;
    margin-top: 0;
  }
  .CommonFormatWrapper .CommonFormatContainer .bodyText:first-child {
    margin-top: 0.5rem;
  }
  .CommonFormatWrapper .CommonFormatContainer .bodyText.bodyTextPC {
    display: none;
  }
}
@media (min-width: 801px) {
  .CommonFormatWrapper .CommonFormatContainer .bodyText.bodyTextM {
    display: none;
  }
}
.CommonFormatWrapper .CommonFormatContainer .menuBtn {
  height: 64px;
  line-height: 64px;
  width: 204px;
  border: none;
  border-radius: 3.75rem;
  background-color: #0055F0;
  color: white;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  color: white;
  font-weight: 900;
  font-family: "NotoSansKR";
  margin-top: 1.875rem;
  cursor: pointer;
}
.CommonFormatWrapper .CommonFormatContainer .menuBtn:hover {
  background-color: white;
  color: #0055F0;
}
.CommonFormatWrapper .CommonFormatContainer .menuBtn:active {
  background-color: #0055F0;
  color: white;
}
@media (max-width: 800px) {
  .CommonFormatWrapper .CommonFormatContainer .menuBtn {
    height: 35px;
    line-height: 35px;
    width: 168px;
    border: none;
    border-radius: 3.75rem;
    background-color: #0055F0;
    color: white;
    text-align: center;
    cursor: pointer;
    font-size: 0.8125rem;
    color: white;
    font-weight: 700;
    font-family: "NotoSansKR";
  }
  .CommonFormatWrapper .CommonFormatContainer .menuBtn:hover {
    background-color: white;
    color: #0055F0;
  }
  .CommonFormatWrapper .CommonFormatContainer .menuBtn:active {
    background-color: #0055F0;
    color: white;
  }
}
.CommonFormatWrapper:first-child {
  background-position: 40% 75%;
}/*# sourceMappingURL=Business.css.map */