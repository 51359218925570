@import '../../../common/Common';

.contactMapWrapper{
    padding: 0 0 pxToVwPC(100) 0;
    height: 100%;
    @include flex($jc:center, $ai:center);
    @include contactMobile{
        @include flex($d:column, $jc:center, $ai:center);
        margin-top: pxToRem(36);
        padding: 0 pxToRem(20);
    }

    .contactMapContainer{
        width: 100%;
        margin-top: 10%;
        @include flex($jc:center, $ai:center);
        @include flex-nonShrink-div();
        @include contactMobile(){
            flex-direction: column;
        }
        .infoContainer{
            // width: pxToVwPC(383);
            // padding-left:pxToRem(80);
            // padding-bottom: pxToVwPC(200);
            @include contactMobile(){
                width: 80vw;
                // padding-left:0;
                // margin-left: pxToVwMobile(60);
                padding-bottom: pxToVwPC(200);
            }
            .infoTitle{
                // width: pxToVwPC(450);
                @include font($s:pxToRem(40), $w:700);
                margin-bottom:16px;
                @include contactMobile(){
                    width: 100%;
                    text-align: left;
                    @include font($s:pxToRem(18), $w:700);
                    margin-top: pxToRem(36);
                }
            }
            .infoAddress{
                @include font($s:pxToRem(13), $w:400)
            }
        }
        .emptyMap{
            @include border();
            height: 100%;
            width: 10%;
            // flex-shrink: 1;
        }
        .emptyDiv{
            width: 3vw;
            @include contactMobile(){
                display: none;
            };
        }
        .mapWrapper{
            .tempMap{
                width: pxToVwPC(690);
                height: auto;
                content: url('./images/ui_bg_map@3x.png');
                @include contactMobile(){
                    width: 100%;
                    content: url('./images/ui_bg_map@2x.png');
                }
            }
            @include contactMobile(){
                margin-right: 0;
            }
        }
    }
    
}