@charset "UTF-8";
/* text-ellipsis (말줄임..)  */
/* @param : $l ( 기본값 1 줄 ) */
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.displayNone {
  display: none;
}

button, input, .cursorPointer {
  cursor: pointer;
}

.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 20px;
  align-items: center;
}
.popupWrapper img {
  max-width: 90vw;
  width: 100%;
  max-height: 90vh;
  height: auto;
}
.popupWrapper .popupBtnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.popupWrapper .popupBtnWrapper .popupBtn {
  z-index: 100;
  max-height: 48px;
  font-size: 2vw;
  color: transparent;
  font-family: "NotoSansKR";
}
.popupWrapper .popupBtnWrapper .popupBtn:first-child {
  width: 33%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.popupWrapper .popupBtnWrapper .popupBtn:nth-child(2) {
  width: 6%;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (max-width: 800px) {
  .popupWrapper .popupBtnWrapper .popupBtn {
    margin-right: 0;
    font-size: 3vw;
    color: black;
    font-family: "NotoSansKR";
  }
}

@font-face {
  font-family: "NotoSansKR";
  src: url("/public/fonts/NotoSansKR-Thin.otf") format("woff"), url("/public/fonts/NotoSansKR-Thin.otf") format("woff2"), url("/public/fonts/NotoSansKR-Thin.otf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "NotoSansKR";
  src: url("/public/fonts/NotoSansKR-Light.otf") format("woff"), url("/public/fonts/NotoSansKR-Light.otf") format("woff2"), url("/public/fonts/NotoSansKR-Light.otf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "NotoSansKR";
  src: url("/public/fonts/NotoSansKR-Regular.otf") format("woff"), url("/public/fonts/NotoSansKR-Regular.otf") format("woff2"), url("/public/fonts/NotoSansKR-Regular.otf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "NotoSansKR";
  src: url("/public/fonts/NotoSansKR-Medium.otf") format("woff"), url("/public/fonts/NotoSansKR-Medium.otf") format("woff2"), url("/public/fonts/NotoSansKR-Medium.otf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "NotoSansKR";
  src: url("/public/fonts/NotoSansKR-Bold.otf") format("woff"), url("/public/fonts/NotoSansKR-Bold.otf") format("woff2"), url("/public/fonts/NotoSansKR-Bold.otf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "NotoSansKR";
  src: url("/public/fonts/NotoSansKR-Black.otf") format("woff"), url("/public/fonts/NotoSansKR-Black.otf") format("woff2"), url("/public/fonts/NotoSansKR-Black.otf") format("truetype");
  font-weight: 900;
}
* {
  font-family: "NotoSansKR", sans-serif, AppleSDGothic, Apple SD Gothic Neo !important;
  word-break: keep-all;
}

body {
  margin: 0;
  box-sizing: content-box;
}
body::-webkit-scrollbar {
  display: none;
}

.goUpIcon {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  z-index: 50;
}
@media (max-width: 800px) {
  .goUpIcon {
    bottom: 50px;
    right: 20px;
    width: 35px;
    height: 35px;
  }
}/*# sourceMappingURL=index.css.map */