@import '../../common/Common';

$vert-move-height : -30px;
$vert-move-sec : 1s;

#mouseIcon{
    width: 45px;
    height: 45px;
    z-index: 20;
    @include position($p:fixed, $b:10px, $l:0, $r: 0, $m:auto);
    img{
        width: 100%;
        height: 100%;
    }
};
div.vert-move {
    -webkit-animation: mover $vert-move-sec infinite alternate;
    animation: mover $vert-move-sec infinite  alternate;
}
div.vert-move {
    -webkit-animation: mover $vert-move-sec infinite alternate;
    animation: mover $vert-move-sec infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY($vert-move-height); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY($vert-move-height); }
}
.outer {
    width: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    .inner {
        height: 100%;
    }
    .divider {
        width: 100%;
        height: 1px;
        background-color: transparent;
    }
    .dividerFull {
        @extend .divider;
        height: 0.1px;
    }

}