@charset "UTF-8";
/* text-ellipsis (말줄임..)  */
/* @param : $l ( 기본값 1 줄 ) */
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.displayNone {
  display: none;
}

button, input, .cursorPointer {
  cursor: pointer;
}

.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 20px;
  align-items: center;
}
.popupWrapper img {
  max-width: 90vw;
  width: 100%;
  max-height: 90vh;
  height: auto;
}
.popupWrapper .popupBtnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.popupWrapper .popupBtnWrapper .popupBtn {
  z-index: 100;
  max-height: 48px;
  font-size: 2vw;
  color: transparent;
  font-family: "NotoSansKR";
}
.popupWrapper .popupBtnWrapper .popupBtn:first-child {
  width: 33%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.popupWrapper .popupBtnWrapper .popupBtn:nth-child(2) {
  width: 6%;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (max-width: 800px) {
  .popupWrapper .popupBtnWrapper .popupBtn {
    margin-right: 0;
    font-size: 3vw;
    color: black;
    font-family: "NotoSansKR";
  }
}

.footerPageWrapper {
  width: 100%;
  height: 677px !important;
  text-align: center;
  word-break: word-break;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: transparent;
  background-image: url("./images/about_bg_full.png");
}
@media (max-width: 800px) {
  .footerPageWrapper {
    background-position: 10 0% 80%;
    height: 589px !important;
  }
}
@media (max-width: 800px) and (max-height: 589px) {
  .footerPageWrapper {
    height: 100vh !important;
  }
}
.footerPageWrapper.noPartner {
  height: 275px !important;
}
@media (max-width: 800px) {
  .footerPageWrapper.noPartner {
    height: 229px !important;
  }
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  .footerPageWrapper.noPartner {
    display: none;
  }
}
.footerPageWrapper .partnerWrapper {
  height: 402px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 1.875rem;
}
@media (max-width: 800px) {
  .footerPageWrapper .partnerWrapper {
    height: 360px !important;
    justify-content: center;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  .footerPageWrapper .partnerWrapper {
    justify-content: center;
  }
  .footerPageWrapper {
    height: calc(100vh - 56px) !important;
    justify-content: space-around;
  }
}
@media (max-width: 800px) and (max-height: 589px) {
  .footerPageWrapper .partnerWrapper {
    height: 50vh !important;
  }
}
.footerPageWrapper .partnerWrapper .topText {
  font-size: 3.25rem;
  color: white;
  font-weight: 900;
  font-family: "NotoSansKR";
}
@media (max-width: 800px) {
  .footerPageWrapper .partnerWrapper .topText {
    font-size: 1.625rem;
    color: white;
    font-weight: 700;
    font-family: "NotoSansKR";
  }
}
.footerPageWrapper .partnerWrapper .middleText {
  font-size: 1.625rem;
  color: white;
  font-weight: 700;
  font-family: "NotoSansKR";
}
@media (max-width: 800px) {
  .footerPageWrapper .partnerWrapper .middleText {
    width: 80%;
    word-break: keep-all;
    font-size: 1.125rem;
    color: white;
    font-weight: 700;
    font-family: "NotoSansKR";
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.footerPageWrapper .partnerWrapper .bottomBtn {
  height: 64px;
  line-height: 64px;
  width: 180px;
  border: 3px solid white;
  border-radius: 60px;
  background-color: transparent;
  color: white;
  text-align: center;
  cursor: pointer;
  line-height: 58px;
  font-size: 1.125rem;
  color: white;
  font-weight: 700;
  font-family: "NotoSansKR";
}
.footerPageWrapper .partnerWrapper .bottomBtn:hover {
  background-color: white;
  color: transparent;
}
.footerPageWrapper .partnerWrapper .bottomBtn:active {
  background-color: transparent;
  color: white;
}
.footerPageWrapper .partnerWrapper .bottomBtn:hover {
  color: white;
  background-color: transparent;
}
@media (max-width: 800px) {
  .footerPageWrapper .partnerWrapper .bottomBtn {
    width: 208px;
  }
}
.footerPageWrapper .footerContainer {
  width: 100%;
  height: 275px;
  background: #1E1E1E;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 3.14625rem;
}
@media (max-width: 800px) {
  .footerPageWrapper .footerContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding: 5%;
    text-align: left;
    height: 229px;
  }
  .footerPageWrapper .footerContainer > div, .footerPageWrapper .footerContainer > img {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
}
@media (max-width: 800px) and (max-height: 589px) {
  .footerPageWrapper .footerContainer {
    height: 45vh !important;
  }
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  .footerPageWrapper .footerContainer {
    height: 100% !important;
  }
}
.footerPageWrapper .footerContainer .footerLogo {
  width: 5.0625rem;
  height: 1.625rem;
  margin-right: 1.09375vw;
  margin-left: 2.6041666667vw;
}
.footerPageWrapper .footerContainer .footerAddress, .footerPageWrapper .footerContainer .footerCopy {
  font-size: 0.8125rem;
}
.footerPageWrapper .footerContainer .footerAddress {
  margin-right: 3.125rem;
  flex-shrink: 3;
}
@media (max-width: 800px) {
  .footerPageWrapper .footerContainer .footerAddress {
    margin-right: 0;
  }
}
.footerPageWrapper .footerContainer .footerCopy {
  margin-right: 4.375rem;
  flex-shrink: 3;
}
@media (max-width: 800px) {
  .footerPageWrapper .footerContainer .footerCopy {
    margin-right: 0;
  }
}
.footerPageWrapper .footerContainer .footerPolicy, .footerPageWrapper .footerContainer .footerPolicyM {
  font-weight: 700;
  width: 9.375rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.footerPageWrapper .footerContainer .footerPolicy a, .footerPageWrapper .footerContainer .footerPolicyM a {
  text-decoration: none;
  color: white;
}
.footerPageWrapper .footerContainer .footerPolicy .footersocial, .footerPageWrapper .footerContainer .footerPolicyM .footersocial {
  width: 1.815rem;
  height: 1.28rem;
  margin-left: 1.09375vw;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  background-color: transparent;
  background-image: url("./images/ui_ic_youtube@3x.png");
}
@media (min-width: 801px) {
  .footerPageWrapper .footerContainer .footerPolicyM {
    display: none;
  }
}/*# sourceMappingURL=Footer.css.map */