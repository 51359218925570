@charset "UTF-8";
/* text-ellipsis (말줄임..)  */
/* @param : $l ( 기본값 1 줄 ) */
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.displayNone {
  display: none;
}

button, input, .cursorPointer {
  cursor: pointer;
}

.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 20px;
  align-items: center;
}
.popupWrapper img {
  max-width: 90vw;
  width: 100%;
  max-height: 90vh;
  height: auto;
}
.popupWrapper .popupBtnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.popupWrapper .popupBtnWrapper .popupBtn {
  z-index: 100;
  max-height: 48px;
  font-size: 2vw;
  color: transparent;
  font-family: "NotoSansKR";
}
.popupWrapper .popupBtnWrapper .popupBtn:first-child {
  width: 33%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.popupWrapper .popupBtnWrapper .popupBtn:nth-child(2) {
  width: 6%;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (max-width: 800px) {
  .popupWrapper .popupBtnWrapper .popupBtn {
    margin-right: 0;
    font-size: 3vw;
    color: black;
    font-family: "NotoSansKR";
  }
}

#mouseIcon {
  width: 45px;
  height: 45px;
  z-index: 20;
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
#mouseIcon img {
  width: 100%;
  height: 100%;
}

div.vert-move {
  animation: mover 1s infinite alternate;
}

div.vert-move {
  animation: mover 1s infinite alternate;
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}
.outer {
  width: 100%;
  overflow-y: auto;
}
.outer::-webkit-scrollbar {
  display: none;
}
.outer .inner {
  height: 100%;
}
.outer .divider, .outer .dividerFull {
  width: 100%;
  height: 1px;
  background-color: transparent;
}
.outer .dividerFull {
  height: 0.1px;
}/*# sourceMappingURL=Scrolling.css.map */