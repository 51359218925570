@charset "UTF-8";
/* text-ellipsis (말줄임..)  */
/* @param : $l ( 기본값 1 줄 ) */
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.displayNone {
  display: none;
}

button, input, .cursorPointer {
  cursor: pointer;
}

.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 20px;
  align-items: center;
}
.popupWrapper img {
  max-width: 90vw;
  width: 100%;
  max-height: 90vh;
  height: auto;
}
.popupWrapper .popupBtnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.popupWrapper .popupBtnWrapper .popupBtn {
  z-index: 100;
  max-height: 48px;
  font-size: 2vw;
  color: transparent;
  font-family: "NotoSansKR";
}
.popupWrapper .popupBtnWrapper .popupBtn:first-child {
  width: 33%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.popupWrapper .popupBtnWrapper .popupBtn:nth-child(2) {
  width: 6%;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (max-width: 800px) {
  .popupWrapper .popupBtnWrapper .popupBtn {
    margin-right: 0;
    font-size: 3vw;
    color: black;
    font-family: "NotoSansKR";
  }
}

.contactMapWrapper {
  padding: 0 0 5.2083333333vw 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 950px) {
  .contactMapWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2.25rem;
    padding: 0 1.25rem;
  }
}
.contactMapWrapper .contactMapContainer {
  width: 100%;
  margin-top: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-basis: 62.5vw;
  flex-shrink: 0;
}
@media (max-width: 950px) {
  .contactMapWrapper .contactMapContainer {
    flex-direction: column;
  }
}
@media (max-width: 950px) {
  .contactMapWrapper .contactMapContainer .infoContainer {
    width: 80vw;
    padding-bottom: 10.4166666667vw;
  }
}
.contactMapWrapper .contactMapContainer .infoContainer .infoTitle {
  font-size: 2.5rem;
  color: black;
  font-weight: 700;
  font-family: "NotoSansKR";
  margin-bottom: 16px;
}
@media (max-width: 950px) {
  .contactMapWrapper .contactMapContainer .infoContainer .infoTitle {
    width: 100%;
    text-align: left;
    font-size: 1.125rem;
    color: black;
    font-weight: 700;
    font-family: "NotoSansKR";
    margin-top: 2.25rem;
  }
}
.contactMapWrapper .contactMapContainer .infoContainer .infoAddress {
  font-size: 0.8125rem;
  color: black;
  font-weight: 400;
  font-family: "NotoSansKR";
}
.contactMapWrapper .contactMapContainer .emptyMap {
  border: 1px solid black;
  height: 100%;
  width: 10%;
}
.contactMapWrapper .contactMapContainer .emptyDiv {
  width: 3vw;
}
@media (max-width: 950px) {
  .contactMapWrapper .contactMapContainer .emptyDiv {
    display: none;
  }
}
.contactMapWrapper .contactMapContainer .mapWrapper .tempMap {
  width: 35.9375vw;
  height: auto;
  content: url("./images/ui_bg_map@3x.png");
}
@media (max-width: 950px) {
  .contactMapWrapper .contactMapContainer .mapWrapper .tempMap {
    width: 100%;
    content: url("./images/ui_bg_map@2x.png");
  }
}
@media (max-width: 950px) {
  .contactMapWrapper .contactMapContainer .mapWrapper {
    margin-right: 0;
  }
}/*# sourceMappingURL=ContactMap.css.map */