@charset "UTF-8";
/* text-ellipsis (말줄임..)  */
/* @param : $l ( 기본값 1 줄 ) */
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.displayNone {
  display: none;
}

button, input, .cursorPointer {
  cursor: pointer;
}

.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 20px;
  align-items: center;
}
.popupWrapper img {
  max-width: 90vw;
  width: 100%;
  max-height: 90vh;
  height: auto;
}
.popupWrapper .popupBtnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
.popupWrapper .popupBtnWrapper .popupBtn {
  z-index: 100;
  max-height: 48px;
  font-size: 2vw;
  color: transparent;
  font-family: "NotoSansKR";
}
.popupWrapper .popupBtnWrapper .popupBtn:first-child {
  width: 33%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.popupWrapper .popupBtnWrapper .popupBtn:nth-child(2) {
  width: 6%;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media (max-width: 800px) {
  .popupWrapper .popupBtnWrapper .popupBtn {
    margin-right: 0;
    font-size: 3vw;
    color: black;
    font-family: "NotoSansKR";
  }
}

.iframCloseBtn {
  position: absolute;
  top: -40px;
  right: 0;
  background-color: black;
  width: 46px;
  height: 41px;
  text-align: center;
  line-height: 8;
}
.iframCloseBtn svg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 38px;
}
@media (max-width: 800px) {
  .iframCloseBtn {
    top: -25px;
    width: 25px;
    height: 25px;
  }
  .iframCloseBtn svg {
    font-size: 25px;
  }
}

@media (max-width: 800px) {
  .PC {
    display: none;
  }
}

@media (min-width: 801px) {
  .Mobile {
    display: none;
  }
}/*# sourceMappingURL=FrameYoutube.css.map */