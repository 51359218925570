
@import './components/common/Common';

@font-face {
    font-family: "NotoSansKR";
    src: url('/public/fonts/NotoSansKR-Thin.otf') format('woff'),
    url('/public/fonts/NotoSansKR-Thin.otf') format('woff2'),
    url('/public/fonts/NotoSansKR-Thin.otf') format('truetype');
    font-weight: 100;
}
@font-face {
    font-family: "NotoSansKR";
    src: url('/public/fonts/NotoSansKR-Light.otf') format('woff'),
    url('/public/fonts/NotoSansKR-Light.otf') format('woff2'),
    url('/public/fonts/NotoSansKR-Light.otf') format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: "NotoSansKR";
    src: url('/public/fonts/NotoSansKR-Regular.otf') format('woff'),
    url('/public/fonts/NotoSansKR-Regular.otf') format('woff2'),
    url('/public/fonts/NotoSansKR-Regular.otf') format('truetype');
    font-weight: 400;
}
@font-face {
    font-family: "NotoSansKR";
    src: url('/public/fonts/NotoSansKR-Medium.otf') format('woff'),
    url('/public/fonts/NotoSansKR-Medium.otf') format('woff2'),
    url('/public/fonts/NotoSansKR-Medium.otf') format('truetype');
    font-weight: 500;
}
@font-face {
    font-family: "NotoSansKR";
    src: url('/public/fonts/NotoSansKR-Bold.otf') format('woff'),
    url('/public/fonts/NotoSansKR-Bold.otf') format('woff2'),
    url('/public/fonts/NotoSansKR-Bold.otf') format('truetype');
    font-weight: 700;
}
@font-face {
    font-family: "NotoSansKR";
    src: url('/public/fonts/NotoSansKR-Black.otf') format('woff'),
    url('/public/fonts/NotoSansKR-Black.otf') format('woff2'),
    url('/public/fonts/NotoSansKR-Black.otf') format('truetype');
    font-weight: 900;
}

@mixin font-face($name:NotoSansKR, $weight: noraml, $style: normal) {
    font-family: 'NotoSansKR';
    font-weight: $weight;
}

*{
    font-family: 'NotoSansKR', sans-serif, AppleSDGothic, Apple SD Gothic Neo !important;
    word-break: keep-all;
}

body {
    margin: 0;
    &::-webkit-scrollbar {
        display: none;
    }
    box-sizing: content-box;
}
.goUpIcon{
    @include position($p:fixed, $b: 50px, $r:50px);
    width: 50px;
    height: 50px;
    z-index: 50;
    @include mobile(){
        bottom:50px;
        right: 20px;
        width: 35px;
        height: 35px;
    }
}