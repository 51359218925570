@import '../../../common/Common';

@include mobile{
    .aboutMainContainer,
    // .history,
    .carouselContainer,
    .historyPcBlank
    {
        display: none !important;
    }
}

.mobileHistorySecond{ 
        display: none;
    @include HistoryMobile{
        display: block;
    }
}

.mAboutMainContainer,
.mCarouselContainer
{
    display: none;
    @include mobile{
        display: block !important;
        @media screen and (orientation:landscape){
            width: auto;
        }
    }
}

.aboutMain{
    @include backgroundImage($bi:'./images/ui_bg_about_baner.png', $bs:cover);
    @include flex($jc:center);
    
    .aboutMainContainer{
        padding: 0 25px;

        .aboutTitleText{
            @include font($s:pxToRem($s:64), $c:white, $w:900);
        }
        .aboutContentText{
            @include font($s:pxToRem($s:26), $c:white, $w:700);
        }
    }
    .mAboutTitleText{
        @include font($s:26px, $c:white, $w:700);
        text-align: center;
    }
    .mAboutContentText{
        @include font($s:12px, $c:white, $w:400);
        text-align: center;
    }
    height: calc(100vh - $pc-navbar-height) !important;
    @include mobile(){
        height: calc(100vh - $mobile-navbar-height) !important;
    }
}
.inner.historyEmpty{
    height: calc(100vh - $pc-navbar-height) !important;
    @include mobile(){
        height: calc(100vh - $mobile-navbar-height) !important;
    }
    // @media (min-height:1200px){
    //     display: none;
    // }
}
.historyContainer{
    @include flex($d:column);
    &.newsWrapper{
        @include flex($d:column, $jc:flex-end);
    }
    // height: 100%;
    // @include mobile(){
        height: auto;
        padding: 30px 0 0;
    // }
    .historyHeaderText{
        @include font($s:pxToRem($s:52), $c:#1E1E1E, $w:900);
        @include HistoryMobile{
            @include font($s:26px, $c:#1E1E1E, $w:700);
            align-self: center;
            // margin: 20px 0 0 0;
        }
        @include HistoryDesktop{
            margin-top: 50px;
            margin-bottom: 50px;
            @include font($s:pxToRem(52), $c:#1E1E1E, $w:900);
        }
    }
    .history{
        width: 100%;
        @include flex($jc:center);
        .historyContentsWrapper{
            width: 1200px;
            height: 750px;
            @include flex($d:column, $jc:space-between);
            position: relative;
            .historyContentsBg{
                z-index: 1;
                // background-image: url('./images/ui_vertical_line.png');
                @include backgroundImage($bp:left top, $bs:100% 100%);
                width: 1200px;
                height: 640px;
                @include position($t:0, $l:0, $r:0, $m:auto)
            }
            // @include mobile(){
                padding : 0 20px;
                height: auto;
            // }
        }
        
    }
}

.historyContents{
    z-index: 2;
    @include flex($ai:flex-start);
    @include mobile(){
        // height: calc(100vh - $mobile-navbar-height) !important;
        @include flex($ai:flex-start, $d:column);
    }
    width: 100%;
    border-top: 1px solid rgba(0,0,0,0.1);
    padding: 3% 0;
    &:first-child{
        border-top: 1px solid transparent;
        @include mobile(){
            // height: calc(100vh - 96px) !important;
        }
    }
    .historyYear{
        @include font($s:pxToRem($s:36), $c:#0055F0, $w:700);
        // margin-top: - pxToRem($s:10);
        width: pxToRem(180);
        flex-shrink: 0;
        @include mobile(){
            @include font($s:pxToRem($s:26), $c:#0055F0, $w:700);
        }
    }
    .historylineWrapper{
        width: pxToRem(78);
        flex-shrink: 0;
        margin-top: pxToRem(9);
        margin-right: pxToRem(48);
        img{
            width: 100%;
            height: auto;
        }
    }
    .historyTextWrapper{
        width: 100%;
        margin: pxToRem(11) 0;
        @include font($s:pxToRem(20));
        @include mobile(){
            @include font($s:pxToRem($s:16));
        }
        .historyTextContainer{
            @include flex($ai:flex-start);
            flex-shrink: 2;
            margin-bottom: 10px;
            letter-spacing: -0.3px;
            .historyMonth{
                font-weight: 700;
                text-align: right;
                width: 50px;
                @include mobile(){
                    width: 20px;
                }
                margin-right: 30px;
                flex-shrink: 0;
            }
            .historyText{
                flex-shrink: 3;
            }
        }
    }
}

.emptyHistoryPC{
    height: 10%;
    // max-height: 105px;
    flex-shrink: 3;
    // @include HistoryMobile(){
    //     display: none;
    // };
}

// .mHistory{
//     display: none;
//     padding-left: 20px;
//     margin-top: 20px;
//     width: 100%;
//     @include HistoryMobile{
//         height: 100%;
//         @include flex($d:column, $ai:none);
//         padding-left:20px;
//     }
//     .mHistoryYear{
//         @include font($s:26px, $c:#0055F0, $w:700);
//         margin-bottom: 10px;
//         @include HistoryMobile{
//             font-size: 1.5vw;
//         }
//     }   
//     .mHistoryContent{
//         @include font($s:12px, $c:1E1E1E, $w:500);
//         @include flex($ai:flex-start, $jc:unset);
//         margin: 0 5px 10px -12px;
//         @include HistoryMobile{
//             font-size: 1vw;
//         }
//         .mHistoryMonth{
//             font-weight: 500;
//             width: 10%;
//             text-align: right;
//             margin-right: 10px;
//         }
//         .mHistoryText{
//             width: 84%;
//         }
//     }
// }

.newsContainer{
    @include backgroundColor($bc:#F3F5F7);
    @include flex($d:column,$jc:center);
    height: 800px !important;
    width: 100vw;
    padding: 20px 0;
    @include mobile{
        justify-content: center;
        height: 360px !important;
        @media (max-height:360px){
            height: 100% !important;
        }
    }
    .newsHeader{
        @include font($s:pxToRem($s:52), $c:#1E1E1E, $w:900);
        @include mobile{
            @include font($s:26px, $c:#1E1E1E, $w:700);
        }
        @include desktop{
            margin-bottom: $newsMarginTop;
            @media (max-height:1100px){
                @include font($s:pxToVwPC($s:52), $c:#1E1E1E, $w:900);
            }
        }
    }
}

// @include mobile{
//     @media (max-width:370px) {
//         .slick-list .slick-active{
//             width: 220px !important;
//         }
//         .slick-list{
//             margin-left: -46px !important;
//         }
//         .slick-track{
//             width: 2000px !important;
//             display: flex;
//             justify-content:space-around;
//         }
//     } 
// }

.carouselContainer{
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 80vw;
    height: 40vh;
    margin-bottom: 134px;
}

.carouselContainer> .ant-carousel {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}