@import '../../../common/Common';
.CommonFormatWrapper{
    @include backgroundImage($bs:cover);
    @include flex($ai:flex-start);
    .emptyDiv{
        flex-shrink: 3;
        flex-basis: pxToVwPC(360);
        @include mobile(){
            display: none;
        }
    }
    .CommonFormatContainer{
        @include flex($d:column,$jc:center, $ai:flex-start);
        flex-shrink: 0;
        flex-basis: pxToVwPC(1200);
        @include desktop(){
            min-width: 800px;
        }
        @include mobile(){
            min-width : 99vw;
        }
        height: 100%;
        padding-left: pxToRem(16);
        padding-right: pxToRem(16);
        .emptyDiv{
            display: none;
            // width: 100%;
            // height: 23%;
            // flex-shrink: 3;
        }
        img{
            height: 99px;
            width: auto;
            @include mobile(){
                height: 62px;
                width: auto;
            }
        }
        .headerText{
            @include font($c:white, $s:pxToRem(64), $w:900);
            word-break: keep-all;
            // margin-top: pxToVhPCNoNav(24.3);
            @include mobile(){
                font-size : pxToRem(26);
                margin-bottom: pxToRem(8);
            }
        }
        .bodyText{
            @include font($c:white, $s:pxToRem(26), $w:400);
            margin-top: pxToRem(8);
            @include mobile(){
                font-size : pxToRem(12);
                margin-top : 0;
                &:first-child{
                    margin-top: pxToRem(8);
                }
                &.bodyTextPC{
                    display: none;
                }
            }
            @include desktop(){
                &.bodyTextM{
                    display: none;
                }
            }
        }
        .menuBtn{
            @include divBtn($bc:$ymx-bluePoint100, $w:204px, $h:64px, $c:white, $b:none, $br:pxToRem(60));
            @include font($s:1rem, $w:900, $c:white);
            margin-top: pxToRem(30);
            cursor: pointer;
            @include mobile(){
                @include divBtn($bc:$ymx-bluePoint100, $w:168px, $h:35px, $c:white, $b:none, $br:pxToRem(60));
                @include font($s:pxToRem(13), $w:700, $c:white);
            }
        }
    }
    &:first-child{
        background-position: 40% 75%;
    }
}