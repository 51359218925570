
@import '../../common/Common';

.iframCloseBtn{
    position: absolute;
    top:-40px;
    right: 0;
    background-color: black;
    width: 46px;
    height: 41px;
    text-align: center;
    line-height: 8;
    svg {
        position: absolute;
        top:0;
        right: 0;
        left : 0;
        margin-left: auto;
        margin-right: auto;
        font-size: 38px;
    }
    @include mobile(){
        top:-25px;
        width: 25px;
        height: 25px;
        svg {
            font-size: 25px;
        }
    }
}
.PC{
    @include mobile(){
        display: none;
    }
}
.Mobile{
    @include desktop(){
        display: none;
    }
}