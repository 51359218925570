$pc-navbar-height : 80px;
$pc-navbarItem-width : 90px;
$ymx-blackPoint :#1E1E1E;
$ymx-blackPoint500 :#EAEAEA;
$ymx-blackPoint400 : #B7B7B7;
$ymx-blackPoint200 : #626262;
$ymx-redAccent :#F70101;
$ymx-bluePoint100 : #0055F0;
$ymx-bluePointActive : #00A5FA;
$ymx-yellow_Sub : #FFD200;
$mobile-navbar-height : 56px;
$path--rel : './';
$newsMarginTop : 50px;
// Breakpoints
$breakpoint-mobile: 800px;

@mixin mobile {
    @media (max-width: 800px) {
        @content;
    }
}
@mixin contactMobile {
    @media (max-width: 950px) {
        @content;
    }
}
@mixin HistoryMobile {
    @media (max-width: 900px) {
        @content;
    }
}
@mixin HistoryDesktop {
    @media (min-width: 901px), (min-height: 1211px) {
        @content;
    }
}
@mixin desktop {
    @media (min-width: 801px) {
        @content;
    }
}
@mixin WideDesktop {
    @media (min-width: 1920px) {
        @content;
    }
}
@mixin WideMobileLandscape {
    @media (max-height: 530px) {
        @content;
    }
}
@function pxToRem($s) {
    @return #{calc($s / 16)}rem
};
@function pxToVwPC($s) {
    @return #{calc($s / 1920)*100}vw
};
@function pxToVhPC($s) {
    @return #{($s/1160)*100}vh
};
@function pxToVhPCNoNav($s) {
    @return #{($s/1080)*100}vh
};
@function pxToVwMobile($s) {
    @return #{($s/360)*100}vw
};
@function pxToVhMobile($s) {
    @return #{($s/640)*100}vw
};
@mixin position(
    $p: absolute, $t: null, $b: null, $l: null, $r: null, $m:null
) {
    position: $p;
    top: $t;
    bottom: $b;
    left: $l;
    right: $r;
    margin-left: $m;
    margin-right: $m;
    @if not ($m ==null) {
        text-align: center
    }
    @content;
}
@mixin flex(
    $d:row, $jc:flex-start, $ai:center
) {
    display: flex;
    flex-direction: $d;
    justify-content: $jc;
    align-items: $ai;
    @content;
}
@mixin border(
    $w:1px, $s:solid, $c:black
    ) {
    border: $w $s $c;
    @content;
}

@mixin backgroundImage($bi: null ,$br : no-repeat, $bp : center center, $bc : transparent, $bs : 100% auto) {
    @if not ($bi == null) {
        background-image: url($bi);
    }
    background-repeat: $br;
    background-position: $bp;
    background-size: $bs;
    background-color: $bc;
    @content;
}
@mixin backgroundColor($bc:white, $c:black) {
    background-color: $bc;
    color : $c;
    @content;
}

@mixin font($s:null, $c:black, $w:null, $f: 'NotoSansKR' ) {
    font-size: $s;
    color : $c;
    font-weight: $w;
    font-family: $f;
    @content;
}
@mixin textCenterWordBreak() {
    text-align: center;
    word-break: word-break;
};
@mixin divBtn($b:1px solid black, $br:5px, $bc:transparent, $c:black, $h:50px, $w:200px, $pRL:null) {
    height: $h;
    line-height: $h;
    width: $w;
    border: $b;
    border-radius: $br;
    background-color: $bc;
    color : $c;
    padding-left: $pRL;
    padding-right: $pRL;
    text-align: center;
    cursor: pointer;
    &:hover {
        background-color: $c;
        color : $bc;
    }
    &:active {
        background-color: $bc;
        color : $c;
    }
    @content;
}
@mixin contactSelector(){
    &.selected{
        @include backgroundColor($bc:$ymx-bluePointActive, $c:white);
    }
    &.nonSelected{
        @include backgroundColor($bc:$ymx-blackPoint500, $c:white);
    }
    border-radius: 15px;
    @include font($c:white, $s:pxToRem(13), $w:700);
    width: pxToRem(120);
    height: pxToRem(32);
    line-height: pxToRem(32);
    text-align: center;
    margin-right: pxToRem(24);
    @content;
}
/* text-ellipsis (말줄임..)  */
/* @param : $l ( 기본값 1 줄 ) */
@mixin ellipsis($l: 1) {
    @if ($l == 1) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    } @else { 
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $l;
    }
    @content;
}

@mixin animation($a: fadein, $s:1s, $m:forwards) {
    animation: $a $s;
    -moz-animation: $a $s; /* Firefox */
    -webkit-animation: $a $s; /* Safari and Chrome */
    -o-animation: $a $s; /* Opera */
    animation-fill-mode: $m;
    @content;
}

// Ensure CSS grid works with IE 11 spec.
// https://css-tricks.com/browser-compatibility-css-grid-layouts-simple-sass-mixins/
// sass-lint:disable no-vendor-prefixes, no-duplicate-properties
@mixin display-grid {
    display: -ms-grid;
    display: grid;
  }
  
  // $columns values should be delimited by a space
  @mixin grid-template-columns($columns) {
    -ms-grid-columns: $columns;
    grid-template-columns: $columns;
  }
  
  // $rows values should be delimited by a space
  @mixin grid-template-rows($rows) {
    -ms-grid-rows: $rows;
    grid-template-rows: $rows;
  }
  
  // Can be used in combination with above grid-template-X mixins.
  // These result in the same output:
  // @include grid-template-columns(10px grid-repeat(4, 20px) 30px);
  // @include grid-template-columns(10px 20px 20px 20px 20px 30px);
  @function grid-repeat($repeat, $stuff: 1fr) {
    $list: ();
    @for $i from 1 through $repeat {
      $list: append($list, $stuff, space);
    }
    @return $list;
  }
  
  @mixin grid-column($col-start, $col-end) {
    -ms-grid-column: $col-start;
    -ms-grid-column-span: $col-end - $col-start;
    grid-column: #{$col-start} / #{$col-end};
  }
  
  @mixin grid-row($row-start, $row-end) {
    -ms-grid-row: $row-start;
    -ms-grid-row-span: $row-end - $row-start;
    grid-row: #{$row-start} / #{$row-end};
  }
  
  @mixin grid-align-self($value) {
    -ms-grid-row-align: $value;
    align-self: $value;
  }
  
  @mixin grid-justify-self($value) {
    -ms-grid-column-align: $value;
    justify-self: $value;
  }

  @mixin flex-empty-div() {
    flex-basis: 100%;
    flex-shrink: 4;
    @content;
  }
  @mixin flex-shrink-div() {
    flex-basis: pxToVwPC(1200);
    flex-shrink: 1;
    @content;
  }
  @mixin flex-nonShrink-div() {
    flex-basis: pxToVwPC(1200);
    flex-shrink: 0;
    @content;
  }
//------------------------------------------------------------ keyframes
@keyframes fadeout {
    from {opacity: 1;}
    to{opacity: 0;}
}
@-moz-keyframes fadeout { /* Firefox */
    from {opacity: 1;}
    to{opacity: 0;}
}
@-webkit-keyframes fadeout { /* Safari and Chrome */
    from {opacity: 1;}
    to{opacity: 0;}
}
@-o-keyframes fadeout { /* Opera */
    from {opacity: 1;}
    to{opacity: 0;}
}

@keyframes fadein {
    from {opacity: 0;}
    to{opacity: 1;}
}
@-moz-keyframes fadeout { /* Firefox */
    from {opacity: 0;}
    to{opacity: 1;}
}
@-webkit-keyframes fadeout { /* Safari and Chrome */
    from {opacity: 0;}
    to{opacity: 1;}
}
@-o-keyframes fadeout { /* Opera */
    from {opacity: 0;}
    to{opacity: 1;}
}


.displayNone {
    display:none;
}
button, input, .cursorPointer {
    cursor: pointer;
}



$popupWrapperHeight : 704px;
.popupWrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 20px;
    align-items: center;
    img{
        max-width: 90vw;
        width: 100%;
        max-height: 90vh;
        height: auto;
    }
    .popupBtnWrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @include position($p:relative);
        .popupBtn{
            z-index: 100;
            // @include border();
            max-height: 48px;
            @include font($s:2vw, $c:transparent);
            &:first-child{
                width: 33%;
                @include position($l:0, $b:0)
            }
            &:nth-child(2){
                width: 6%;
                @include position($r:0, $b:0)
            }
            @include mobile(){
                margin-right: 0;
                @include font($s:3vw);
            }
        }
        
    }
}
