@import '../../../common/Common';
.innerM{
    height: 100%;
    display: none;
    @include contactMobile(){
        display: block;
    }
}
.pcHiddenMHidden{
    visibility: hidden;
}
.pcHiddenMNone{
    visibility: hidden;
    @include contactMobile(){
        display: none !important;
    }
}
.pcNoneMNone{
    display: none !important;
    @include contactMobile(){
        display: none !important;
    }
}
.contactOuter{
    height: 100%;
    overflow:auto;
}
.ContactWholeWrapper{
    @include flex($jc:center);
    @include contactMobile(){
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .emptydiv{
        height: 100%;
        flex-shrink: 4;
        @include contactMobile(){
            display: none;
        }
    }
    .ContactWrapper{
        @include border($c:transparent);
        height: 100%;
        width: 1200px;
        min-width: 300px;
        margin-right: 50px;
        margin-left: 50px;
        // @include flex($d:column, $jc:flex-start);  
        .ContactLine{
            height: 1px;
            width: 100%;
            @include backgroundColor($bc:black)
        }
        .ContactHeader{
            margin-top: 5%;
            .ContactHeaderText, .ContactHeaderTitle{
                @include flex($jc:center);
            }
            .ContactHeaderText{
                @include font($s:pxToRem(55), $w:900)
            }
            .ContactHeaderTitle{
                @include font($s:pxToRem(26), $w:700)
            }
            .ContactHeaderSubTitle{
                margin-top: pxToRem(36);
                margin-bottom: pxToRem(36);
                @include flex($jc:flex-end);
                @include font($s:pxToRem(13), $w:400, $c:red);
            }
            @include contactMobile(){
                margin-top: 7%;
                .ContactHeaderText{
                    @include font($s:pxToRem(26), $w:700)
                }
                .ContactHeaderTitle{
                    @include font($s:pxToRem(12), $w:400)
                }
                .ContactHeaderSubTitle{
                    margin-top: pxToRem(24);
                    margin-bottom: pxToRem(24);
                    @include flex($jc:flex-end);
                    @include font($s:pxToRem(13), $w:400, $c:red);
                }
            }
        }
        @include contactMobile(){
            width: 100%;
            margin-right: 0;
            margin-left: 0;
            &>div{
                margin-left: pxToRem(20);
                margin-right: pxToRem(20);
            }
            // padding-left: pxToRem(20);
            // padding-right: pxToRem(20);
        }
        .starMark{
            @include font($c:red);
        }
        .ContactContainer{
            position: relative;
            &.ContactContainerPC{
                @include contactMobile(){
                    display : none
                }
            }
            .ContactInputWrapper{
                width: 100%;
                @include flex($ai:center);
                @include contactMobile(){
                    @include flex($d:column, $ai:flex-start);
                }
                margin: 3% auto;
                &.ContactInputWrapperL{
                    align-items: flex-start;
                }
                &.ContactInputWrapperG{
                    padding-bottom: 1%;
                }
                &.ContactInputWrapperS{
                    justify-content: space-between;
                    @include contactMobile(){
                        // height: 50vh;
                        justify-content: space-around;
                    }
                }
                &.ContactInputWrapperC{
                    display: block;
                }
                textarea, input[type=text]{
                    background-color: #F5F5F5;
                    border-radius: 20px;
                    &::placeholder{
                        @include font($c:#B7B7B7, $s:pxToRem(13), $w:400);
                    }
                    padding-left : 15px;
                    height: pxToRem(46);
                    outline: none;
                    border: none;
                }
                $textareaPadding : 26px;
                textarea{
                    resize: none;
                    overflow: hidden;
                    height: calc(pxToRem(180) - $textareaPadding*2);
                    // width: calc(100% - 30px);
                    width: 100%;
                    padding: $textareaPadding;
                    border-radius: 20px ;
                    margin-top: 8px;
                    @include contactMobile(){
                        padding: 16px;
                    }
                }
                .ContactInputHeader{
                    width : 23%;
                    @include font($s:pxToRem(22), $w:700);
                    @include contactMobile(){
                        // margin-top: pxToRem(8);
                        margin-bottom: pxToRem(8);
                        width : 100%;
                        @include font($s:pxToRem(18), $w:700);
                    }
                    &.ContactFinished{
                        width: 100%;
                        text-align: center;
                        margin-top: 10%;
                        margin-bottom: 10%;
                        @include font($s:pxToRem(26), $w:100, $c:$ymx-blackPoint);
                        @include contactMobile(){
                            @include font($s:pxToRem(16), $w:100, $c:$ymx-blackPoint);
                            margin-top: 40%;
                        }
                    }
                }
                $noHeaderWidth : 11%;
                .ContactInputContainer{
                    width: 90%;
                    @include contactMobile(){
                        width: 100%;
                    }
                    &.ContactCheckboxList{
                        @include flex();
                        display: grid;
                        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
                        justify-items:flex-end;
                        row-gap: 5%;
                        @include contactMobile(){
                            @include flex();
                        }
                        .ContactCheckboxItem{
                            @include contactSelector(){
                                height:pxToRem(32);
                            }
                            margin-right: pxToRem(100);
                            &:first-child{
                                margin-right: pxToRem(60);
                            }
                        }
                    }
                    &.ContactInputList{
                        display: grid;
                        grid-template-columns: 33.3% 33.3% 33.3%;
                        // gap: 3%;
                        row-gap:10%;
                        .ContactInputitems{
                            @include flex($jc:flex-end);
                            @include contactMobile(){
                                @include flex($d:column, $ai:flex-start);
                            }
                            div{
                                width:35%;
                                word-break: keep-all;
                                text-align: right;
                                @include font($s:pxToRem(13), $w:700);
                                @include contactMobile(){
                                    text-align: left;
                                    width: 100%;
                                    margin-top: pxToRem(16);
                                    margin-bottom: pxToRem(8);
                                    &.firstItems{
                                        margin-top: 0 !important;
                                    }
                                }
                                @include position($p:relative);
                                padding-right: 15px;
                                .starMark{
                                    @include position($p:absolute);
                                }
                            }
                            input{
                                width: 65%;
                            }
                        }
                        @include contactMobile(){
                            height: 100%;
                            row-gap: 7%;
                            @include flex($d:column, $ai:flex-start);
                            .ContactInputitems{
                                width: 100%;
                                input{
                                    width: 100% !important;
                                }
                            }
                            // .ContactInputitemsPC{
                            //     display: none
                            // }
                        }
                    }
                    &.ContactCheckboxList2{
                        display: grid;
                        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
                        justify-items: flex-end;
                        row-gap: 5%;
                        .ContactCheckboxItem{
                            @include contactSelector(){
                                width: pxToRem(180);
                                margin-bottom: 1%;
                                margin-right: 0;
                                
                            }
                        }
                        @include contactMobile(){
                        justify-items:flex-start;
                        // div:last-child{
                        //     margin-right:pxToRem(79);
                        // }
                            // justify-content: space-around;
                            .ContactCheckboxItem {
                                margin-bottom: 2%;
                                @include contactSelector(){
                                    width: pxToRem(150);
                                    margin-right: 2%;
                            }
                            }
                        margin-bottom: 3%;
                        }
                        
                        @media (max-width: 403px) {
                            // margin-bottom: 32%;
                            row-gap: 1%;
                        }
                    }
                }
                
                &.ContactPrivacyWrapper{
                    @include flex($jc:flex-end);
                    @include contactMobile(){
                        justify-content: flex-start;
                    }
                    margin-top: pxToRem(24);
                    .ant-checkbox-wrapper{
                        margin-right: pxToRem(12);
                    }
                }
                &.ContactBtnWrapper{
                    @include flex($jc:flex-end);
                    @include contactMobile(){
                        justify-content: center;
                    }
                    margin-top: pxToRem(24);
                    div{
                        @include divBtn($bc:$ymx-bluePoint100, $w:180px, $h:52px, $c:white, $b:none, $br:20px){
                            &:hover{
                                background-color: $ymx-bluePoint100;
                                color:white;
                            }
                        }
                    }
                    .disabled{
                        background-color: #EAEAEA;
                        &:hover{
                            background-color: #EAEAEA;
                            color:white;
                        }
                    }
                }
            }
            @include contactMobile(){
                border:1px solid transparent
            }
        }
        .finishedWrapper{
            @include border();
            text-align: center;
            height: 60vh;
        }
    }
}